import { createAction, props } from '@ngrx/store';
import { ExternalFee } from 'src/app/shared/models/external-fees.model';
import { DispatchTag } from '../../shared/models/dispatch-tag.model';
import { ProductFeature } from '../../shared/models/product-feature.model';
import { VehicleType } from '../../shared/models/vehicle.model';

export const supportFirestoreInit = createAction('[Support agent firestore] Init');

export const dispatchTagChanged = createAction(
  '[Support agent firestore] Dispatch tag changed',
  props<{ tags: DispatchTag[] }>(),
);

export const vehicleTypesChanged = createAction(
  '[Support agent firestore] Vehicle types changed',
  props<{ vehicleTypes: VehicleType[] }>(),
);

export const productFeaturesChanged = createAction(
  '[Support agent firestore] Product features changed',
  props<{ features: ProductFeature[] }>(),
);

export const externalFeesChanged = createAction(
  '[Support agent firestore] External fees pricing chabged',
  props<{ externalFees: ExternalFee[] }>(),
);
