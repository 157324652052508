import { createSelector } from '@ngrx/store';
import { SupportAgentState } from '../support-agent.state';
import { AuthSelectors } from 'src/app/auth';

export const areaListSelector = createSelector(AuthSelectors.areaListSelector, areas => areas);

export const firestoreState = (state: SupportAgentState) => state.firestoreState;

export const dispatchTagsSelector = createSelector(firestoreState, state => state.dispatchTags);

export const vehicleTypesSelector = createSelector(firestoreState, state => state.vehicleTypes);

export const vehicleTypeListSelector = createSelector(vehicleTypesSelector, types =>
  types.length > 1 ? [...types].sort((a, b) => (a.name < b.name ? -1 : 1)) : types,
);

export const productFeaturesSelector = createSelector(firestoreState, state => state.productFeatures);

export const productFeatureListSelector = createSelector(productFeaturesSelector, features =>
  features.length > 1 ? [...features].sort((a, b) => (a.name < b.name ? -1 : 1)) : features,
);

export const externalFeesSelector = createSelector(firestoreState, state => state.externalFees);

export const externalFeesListSelector = createSelector(externalFeesSelector, externalFees =>
  externalFees.length > 1 ? [...externalFees].sort((a, b) => (a.name < b.name ? -1 : 1)) : externalFees,
);
